import "./ImageCarousel.scss";
import { MouseEventHandler, useState} from 'react';
import {Button, useMediaQuery} from "@material-ui/core";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import BackArrow from '@material-ui/icons/ArrowBackIos';
import ForwardArrow from '@material-ui/icons/ArrowForwardIos';
import {FreeMode, Navigation, Grid} from 'swiper';
import { VideoTile } from "./VideoTile";
import { ContentItem } from "journey-shared/journey/ApiTypes";
import { v4 as uuidv4 } from 'uuid';
import "swiper/swiper-bundle.min.css";

export type ImageCarouselProps = {
    items: ContentItem[];
    onClick?: MouseEventHandler;
    className?: string;
    onSaveChange?: (saved: boolean) => void
}

function noop() {}

export function ImageCarousel(props: ImageCarouselProps) {

    const displayOneVideo = useMediaQuery('(max-width:600px)');
    const displayTwoVideos = useMediaQuery('(max-width:900px)');
    const [uniqueId] = useState<string>(uuidv4());

    return(
        <div className={`image-carousel ${props.className ?? ''}`} >
            <Button type='submit' className={`custom_prev_${uniqueId} arrow-button left`}>
                <BackArrow className="arrow-icon" />
            </Button>
            <Button type='submit' className={`custom_next_${uniqueId} arrow-button right`}>
                <ForwardArrow className="arrow-icon" />
            </Button>
            <Swiper
                className="swiper"
                modules={[Navigation, FreeMode, Grid]}
                scrollbar={false}
                freeMode={true}
                navigation={{
                    nextEl: `.custom_next_${uniqueId}`,
                    prevEl: `.custom_prev_${uniqueId}`
                }}
                grid= {{
                    rows: 2,
                    fill: 'row'
                }}
                spaceBetween={displayOneVideo ?  0 : displayTwoVideos ? 20: 30}
                slidesPerView={displayOneVideo ?  1 : displayTwoVideos ? 2 : 3} >
                {
                    props.items.map((item, i) => {
                        switch (item.type) {
                            case 'video':
                                return <SwiperSlide key={i} onClick={props.onClick ?? noop}><VideoTile onSaveChange={props.onSaveChange} video={item} /></SwiperSlide>
                            default:
                                return <SwiperSlide key={i}><div /></SwiperSlide>
                        }
                    })
                }
            </Swiper>
        </div>
    );
}
