import { VideoResponse } from "journey-shared/journey/ApiTypes";
import "./Tile.scss";

export type VideoTileProps = {
    video: VideoResponse;
    showDescription?: boolean;
    showTitle?: boolean;
    className?: string;
    onSaveChange?: (saved: boolean) => void
};

export function VideoTile(props: VideoTileProps) {

    let durationMinutes = '';
    let durationSeconds = '';
    if (props.video.duration) {
        durationMinutes = Math.floor(props.video.duration / 60).toString();
        durationSeconds = (props.video.duration % 60).toString().padStart(2, '0');
    }

    let progress = 0;
    if (props.video.position && props.video.duration) {
        progress = 100 / props.video.duration * props.video.position;
    }

    return (
        <div id={`${props.video.id}`} className={`tile-container ${props.className ?? ''}`}>
            <div id={`${props.video.id}`} className={`image-container ${progress ? 'progress-visible' : ''}`}>
                {(durationMinutes) && <div className="duration">{durationMinutes}:{durationSeconds}</div>}
                <img src={props.video.thumbnailUrl} alt="thumbnail" />
            </div>
            {(props.showTitle ?? true) &&<h3 id={`${props.video.id}`}>{props.video.title}</h3>}
            {(props.showDescription ?? false) && <h4 id={`${props.video.id}`}>{props.video.description}</h4>}
        </div>
    );
}