import {AnalyticsBrowser} from '@segment/analytics-next';
import {User} from "journey-shared/journey/ApiTypes";
import ClientConfig from 'journey-shared/journey/ClientConfig';

const EXTERNAL_USER_ACTION = "external_user_action";
const NAVIGATION = "navigation";

class AnalyticsService {

    private readonly segmentAnalytics: AnalyticsBrowser | null = null;
    private globalMetadata = {};
    private userData = {};
    private enableUserTrackingEvents: boolean | undefined = true;

    constructor() {
        this.segmentAnalytics = AnalyticsBrowser.load({writeKey: ClientConfig.segmentWriteKey});
    }

    setGlobalMetadata(metadata = {}) {
        this.globalMetadata = metadata;
    }

    getPathPageProperties(pathName?: string | null) {
        // pageName is the first name in the path. e.g. /pageName/other/parts
        let finalPageName, finalPathName;
        if (pathName) {
            finalPathName = pathName.toLowerCase();
            finalPageName = (finalPathName.split("/").filter(s => !!s).shift() ?? "home").replace(/-/g, "_")
        } else {
            finalPageName = "home";
            finalPathName = "/";
        }
        return {
            page_name: finalPageName,
            path_name: finalPathName
        }
    }

    /**
     * Only needs to be called once when a user registers (or whenever a user's information changes)
     * In theory and a perfect world, if a user information never changed, we only would have to call identify
     * when a user registers. In practice, we want to call it on registration, login and profile update.
     *
     * @param user user object to get the identifier
     */
    async identifyUser(user: User) {
        if (!this.segmentAnalytics) {
            return;
        }
        this.userData = {
            user_id: user.id,
            company_id: user.company?.id,
            company_code: user.company?.code,
            has_eap: user.company?.hasEAP
        };
        this.enableUserTrackingEvents = user.company?.enableUserTrackingEvents ?? true;

        // To cover all bases, we set company-specific traits twice both by using Segment's built-in fields and our custom fields
        const identifyTraits = {
            company: {
                id: user.company?.id,
                name: user.company?.code,
                has_eap: user.company?.hasEAP
            },
            company_id: user.company?.id,
            company_code: user.company?.code,
            has_eap: user.company?.hasEAP
        }
        await this.segmentAnalytics.identify(user.id ? user.id.toString() : undefined, identifyTraits);
    }

    /**
     * @param userActionName lower-case snake_cased action name
     * @param pathName must come from location.pathname or equivalent
     * @param extraProperties an optional hash of additional event properties
     */

    async trackUserAction(userActionName: string, pathName: string | null = null, extraProperties: any = {}) {
        if (!this.segmentAnalytics) {
            return;
        }
        
        await this.segmentAnalytics.track(
            EXTERNAL_USER_ACTION,
          {
                event_type: EXTERNAL_USER_ACTION,
                event_name: userActionName.toLowerCase(),
                ...this.getPathPageProperties(pathName),
                ...extraProperties,
                ...this.userData,
                ...this.globalMetadata
            }
        );
    }

    /**
     * @param pathName must come from location.pathname or equivalent
     */
     async trackPageNavigation(pathName: string) {
        if (!ClientConfig.enableAnalytics || !this.enableUserTrackingEvents) {
            return;
        }
        if (!this.segmentAnalytics) {
            return;
        }

        // pageName is the first name in the path. e.g. /pageName/other/parts
        const pageProperties = this.getPathPageProperties(pathName);

        await this.segmentAnalytics.track(
          NAVIGATION,
          {
              event_type: NAVIGATION,
              event_name: `go_to_${pageProperties.page_name}`,
              ...pageProperties,
              ...this.userData,
              ...this.globalMetadata
          }
        );
    }

    async resetUser() {
        if (!this.segmentAnalytics) {
            return;
        }
        await this.segmentAnalytics.reset();
    }

}

export default new AnalyticsService();
