import "./MuxVideoPlayer.scss";
import {FC, useEffect} from "react";
import MuxPlayer from "@mux/mux-player-react";
import * as React from "react";

interface MuxVideoPlayerProps{
    videoId: number;
    muxPlaybackId: string | null;
    muxVideoId: string | null;
    title: string | null;
    isComponentVisible: boolean;
    isLoading: boolean;
    duration: number;
    position?: number
}

export const MuxVideoPlayer: FC<MuxVideoPlayerProps> = (props: MuxVideoPlayerProps) => {

    const videoElementRef = React.useRef<any>(null);

    useEffect( () => {
        if(videoElementRef && videoElementRef.current && process.env.REACT_APP_MUX_METRICS_KEY){
            videoElementRef.current.play().catch((error: any) => console.log(error));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[videoElementRef]);

    return(
        <div className="mux-video-player-component">
            { !props.isLoading &&
                (props.muxPlaybackId && props.muxVideoId) ?
                    <MuxPlayer
                        streamType="on-demand"
                        startTime={props.position ?? 0}
                        ref={videoElementRef}
                        playbackId={props.muxPlaybackId}
                        metadata={{
                            video_id: props.videoId,
                            video_title: props.title,
                            player_name: 'zoom-player'
                        }}/>
                    :
                    <div className="mux-player-error-copy">There was an error displaying this video.</div>
            }
        </div>
    );
}
