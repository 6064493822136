import React, {useEffect, useState} from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { MuxVideoPlayer } from "./components/MuxVideoPlayer";
import {
  ApiMuxVideoDetails, ContentItem
} from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import { ImageCarousel } from "./components/ImageCarousel";
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import AnalyticsService from "./misc/AnalyticsService";

/* Import Swiper styles  */
import 'swiper/swiper.scss';

const App: React.FC = () => {
  const [muxVideo, setMuxVideo] = useState<ApiMuxVideoDetails|null>(null);
  const [playlist, setPlaylist] = useState<ContentItem[]|null>(null);
  const [isVideoContentLoading, setIsVideoContentLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    getHomeContent();
    trackZoomAppLaunchEvent();
  }, []);

  const getHomeContent = async () => {
    setIsVideoContentLoading(true);
    try {
      const playlist = await JourneyApiClient.getZoomAppHomeContent();
      setPlaylist(playlist);
      setIsVideoContentLoading(false);
    } catch(error) {
      setIsVideoContentLoading(false);
    }
  }

  const getVideoContent = async (videoId: string) => {
    setIsVideoContentLoading(true);
    try {
      const video = await JourneyApiClient.getPublicVideoById(videoId);
      setMuxVideo(video);

      setIsVideoContentLoading(false);
    } catch(error) {
      setIsVideoContentLoading(false);
    }
  }

  const loadVideo = async (event: any) => {
    await getVideoContent(event.target.parentElement.id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMuxVideo(null);
  };

  const trackZoomAppLaunchEvent = async () => {
    await AnalyticsService.trackUserAction("zoom_app_launch");
}

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '300px',
    aspectRatio: "5 / 3",
  };

  return (
    <div className="App">
      <h1>Destress, Rouse Energy and Have Better Meetings</h1>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {muxVideo && !isVideoContentLoading ?
          <Box sx={modalStyle}>
            <MuxVideoPlayer
              videoId={muxVideo.id}
              duration={muxVideo.durationInSeconds}
              isLoading={isVideoContentLoading}
              isComponentVisible={true}
              muxPlaybackId={muxVideo.muxPlaybackId}
              muxVideoId={muxVideo.muxVideoId}
              title={muxVideo.title}
            />
          </Box>
          : <div></div>}
      </Modal>

      {playlist &&
        <div className="video-listing-row" key={"playlist"}>
            <ImageCarousel items={playlist} onClick={loadVideo} />
        </div>
      }
    </div>
  )
};

export default App;
